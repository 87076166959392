/*
 * @Author: 脉迈
 * @Date: 2021-11-10 11:01:46
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-15 18:17:55
 */
import React, {useEffect, useState} from 'react';
import {getGameList} from '../../api/index';
import TopBar from '../../components/TopBar/TopBar';
import Footer from '../../components/Footer/Footer';
import GameDetail from '../../components/GameDetail/GameDetail';
import styles from './index.module.scss';
import intl from 'react-intl-universal';
import Swiper from 'swiper'
import 'swiper/css/swiper.css'

function Index() {
  const [data, setData] = useState<object[]>([])
  const [detailId, setDetailId] = useState(0)
  // const [current, setCurrent] = useState(-1)
  useEffect(()=>{
    getGameList().then((res:any)=>{
      if(res.code===0){
        setData(res.data)
        // eslint-disable-next-line @typescript-eslint/unified-signatures
        new Swiper('.swiper-container', {
          autoplay: true,//可选选项，自动滑动
          slidesPerView: 'auto',
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      }
    })
  },[])
  return (
      <div style={{backgroundColor: '#f1f1f1'}}>
      <TopBar></TopBar>
      <div className={styles['banner1']}>
        <div className={styles['banner-icon']}></div>
        <div className={styles['banner1-desc']}>{intl.get('1005')}</div>
      </div>
      <div className={styles['banner2']}>
        <div className={styles['banner2-time']}>
        </div>
          <p className={styles['title']}>{intl.get('1006')}</p>
      </div>
      <div className={styles['games']}>
          <div className={styles['games-con']}></div>
          <div className={styles['games-c']}>
            <div className={`${styles['games-container']} swiper-container`}>
              <div className={`swiper-wrapper`}>
              {
                // ${index === current ? styles['active']:''} 
                  data.map((item:any,index:number)=>{
                    return(
                      <div className={`${styles['item']} swiper-slide`} key={index} >
                        {/* <div className={styles['first']} style={{ 'backgroundImage': `url(${item.big_picture})` }}> */}
                        <div className={styles['first']}>
                          <img src={item.big_picture} alt="" className={styles.img}/>
                          <div className={styles['inner']}></div>
                        </div>
                        <div className={styles['item-active']} onClick={()=>{setDetailId(item.id)}}>

                          <img src={item.big_picture} className={styles['send']} alt=""/>
                          <div className={styles.box}>
                            <div className={styles['game-desc']}>
                              <p className={styles['game-name']}>{item.game_name}</p>
                              <p className={styles['game-des']}>{item.game_desc}</p>
                            </div>
                            <div className={styles['game-icon']}>
                              <img src={item.head_picture} alt=""/>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                 {detailId!==0 ? <GameDetail onClose={()=>{setDetailId(0)}} id={detailId}></GameDetail>:<></>}
              </div>
            </div>
            <div className={`${styles['swiper-button-prev']} ${styles['swiper-button']} swiper-button-prev`}></div>
            <div className={`${styles['swiper-button-next']} ${styles['swiper-button']} swiper-button-next`}></div>
          </div>
            <div className={styles['game_icon_w']}>
              
            </div>
        </div>
        <Footer></Footer>
    </div>
  )
}
export default Index;