/*
 * @Author: 脉迈
 * @Date: 2021-11-15 19:47:36
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-15 19:47:36
 */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}