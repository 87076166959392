/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:23:01
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 15:33:55
 */
 // eslint-disable-next-line
export default `
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本平臺承諾保護用戶的隱私。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">本《隱私政策》適用於本平臺提供的用戶端軟體、網站、服務（以下統稱為</span><span style="font-family:微软雅黑">“我們的服務”或“本平臺服務”），包括但不限於適用於電腦、移動智慧終端機的產品及服務（含站外服務，例如：我們的廣告服務和“通過我們的服務申請或分享”的外掛程式等）。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本隱私權政策旨在幫助您瞭解我們會收集哪些資料、為什麼收集這些資料、會利用這些資料做什麼以及我們如何保護這些資料。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">瞭解這些內容，對於您行使個人權利及保護您的個人資訊至關重要，請您在使用本平臺服務前務必認真閱讀本政策。如果您未滿</span><span style="font-family:微软雅黑">18周歲，請在法定監護人的陪同下閱讀本《隱私政策》。如您為未成年人的法定監護人，請特別注意第七條。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">當您開始下載、訪問、使用或繼續使用我們的服務，都意味著同意我們按照本《隱私政策》收集、使用、儲存和分享您的相關資訊。我們的服務均適用本政策。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請您注意我們將會不時地檢查我們的政策，並根據需要對我們的政策進行不時更新。我們誠邀您定期登錄本《隱私政策》相關頁面，以確保瞭解我們發佈的最新《隱私政策》版本。未經您明確同意，我們不會削減您按照本隱私政策所應享有的權利。我們會在本頁面上發佈對本政策所做的任何變更。對於重大變更，我們還會提供更為顯著的通知，如您不同意變更後的內容，您可以選擇停止使用我們的服務；如您仍然繼續使用我們服務的，即表示同意受經修訂的本《隱私政策》的約束。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">關於我們</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本平臺是指包括東和強健網絡有限公司及其關聯公司。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">關聯公司是指在現在、將來控制東和強健網絡有限公司、受東和強健網絡有限公司控制或與東和強健網絡有限公司處於共同控制下的公司、機構。控制指通過所有權、有投票權的股份、合同、實際運營關聯或其他被依法認定的方式直接或間接地擁有影響被控制物件管理</span><span style="font-family:微软雅黑">/經營的能力。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">如您對本《隱私政策》或相關事宜有任何問題，請通過</span><span style="font-family:微软雅黑">NeyaHK2021@gmail.com與我們聯繫。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">目錄</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">一、我們如何收集和使用資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">二、我們如何使用</span><span style="font-family:微软雅黑">&nbsp;Cookie&nbsp;和同類技術</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">三、我們可能分享、轉讓和公開披露的資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">四、我們如何儲存和保護資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">五、我們可能向您發送的資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">六、您如何訪問和管理自己的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">七、廣告服務</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">八、未成年人限制</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">九、通知及變更</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">十、如何聯繫我們</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">一、我們如何收集和使用資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們根據合法、正當、必要的原則，僅收集實現產品功能所必要的資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們提供如下功能和服務時，可能會收集、儲存和使用下列與您有關的資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如果您不提供相關資訊，您可能無法註冊成為我們的用戶或無法享受我們提供的某些產品或服務，或者無法達到使用相關服務擬達到的效果。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）我們將通過以下途徑收集和獲得您的資訊：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）您在註冊本平臺的帳號或使用我們服務時，主動向我們提供的個人資訊，包括個人敏感資訊；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">個人敏感資訊：是指一旦洩露、非法提供或濫用可能危害人身和財產安全，極易導致個人名譽、身心健康受到損害或歧視性待遇等的個人資訊。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本隱私政策中涉及的個人敏感資訊包括您的個人生物識別資訊（包括面部特徵、語音）、個人身份標識資訊（包括身份證資訊）、日誌資訊、通訊錄資訊、個人財產資訊、個人常用設備資訊、其他資訊（包括個人電話號碼、手機號碼、行蹤軌跡資訊、大概地理位置、精准定位資訊、網頁流覽記錄）</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。您同意您的個人敏感資訊按本《隱私政策》所述的目的和方式來處理。相比其他個人資訊，個人敏感資訊可能對您的個人權益影響更大。我們在此善意的提醒您需在謹慎考慮後再向我們提供您的個人敏感資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）您通過我們的服務向其他方提供的共用資訊，以及您使用我們服務時所儲存的資訊。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請注意，如您在我們的服務中其他使用者可見的公開區域內上傳或發佈的資訊中、您對其他人上傳或發佈的資訊作出的回應中公開您的資訊，該等資訊可能會被他人收集並加以使用。當您發現他人不正當地收集或使用您的資訊時，請聯繫我們</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）其他方分享的您的資訊。亦即其他方使用我們的服務時所提供有關您的共用資訊。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）我們獲取的您的資訊。您在使用我們的服務時，我們收集、匯總、記錄的資訊，例如</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">日誌資訊、個人常用設備資訊、定位資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）我們會出於以下目的或業務場景，收集和使用您以下類型的資訊：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、用戶註冊功能及登錄，幫助您成為我們的用戶</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">為便於我們為您提供服務，您需要提供基本註冊或登錄資訊，選擇填寫及</span><span style="font-family:微软雅黑">/或提交使用產品或服務所必須的資訊，可能</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">包括手機號碼、驗證碼匹配結果、昵稱、頭像、電子郵箱位址</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等個人敏感資訊，創建您的帳號、用戶名和密碼，成為我們的註冊用戶。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們可能將通過發送短信驗證碼或通訊運營商提供的其他認證形式來驗證您的身份是否有效。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可以選擇進一步完善本平臺帳號資訊，可以向我們提供您的姓名、生日、性別、註冊帳號名稱、昵稱、密碼、口令、密碼保護問題、電子郵寄地址、位址位置、興趣愛好、頭像照片、手機號碼、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">身份證號</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">等資訊以獲取更加個性化的服務。如果您提供真實有效的手機號碼、淘寶帳號、微信、微博、</span><span style="font-family:微软雅黑">QQ等進行綁定，當您遇到註冊帳號丟失、忘記密碼等帳號問題時，可以更便捷的找回帳號和密碼。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">但如果您不提供這些資訊，將不會影響您使用本平臺提供的基礎服務，但可能會影響到您使用本平臺需要進行實名認證、個性化服務等的附加功能。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如果您僅需使用流覽、搜索等基礎服務，您不需要註冊成為我們的使用者及提供上述資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您還可以通過登錄與我們合作的協力廠商平臺的相關帳戶使用我們的產品與</span><span style="font-family:微软雅黑">/或服務，此時我們可能會獲取您在該等協力廠商帳號項下的相關資訊，例如帳戶名稱、帳戶昵稱、好友關係等，請您在登錄時仔細閱讀相關登錄介面的專門提示。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">此外，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在您主動註銷帳號時，我們將根據本平臺的服務協定約定條件及相關法律法規的要求儘快使其匿名或刪除您的個人資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。您可以聯繫我們處理或按照單項服務的具體指引進行操作。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、為您展示和推送商品或服務、提供搜索功能</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">為改善我們的服務、向您提供個性化的服務，我們可能會根據您的帳號資訊、流覽及搜索記錄、點擊記錄、訂閱資訊、收藏記錄、下載記錄、設備資訊、位置資訊、訂單資訊、</span><span style="font-family:微软雅黑">IP位址、網路環境等資訊及您在使用我們服務時提供的內容資訊（包括不限於評論、跟帖、彈幕、社區等內容資訊）等，提取您的流覽、搜索偏好、行為習慣、位置資訊等資訊作特徵分析和使用者畫像，以便為您提供更合適您和您的設備的定制化服務。例如，向您展現或推薦相關程度更高的搜索結果、資訊流或廣告及推廣資訊。我們可能將您的畫像資訊與您授權的其他方的資訊結合起來，更好的滿足您的需求</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。如果您不想接受我們給您推送的商業廣告，您可隨時通過相應產品退訂功能取消。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">為了讓您快速地找到您所需要的產品</span><span style="font-family:微软雅黑">/服務，我們可能會收集您使用我們的產品與/或服務的</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">日誌資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">及</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">個人常用設備資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">，例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">硬體型號、設備</span><span style="font-family:微软雅黑">MAC位址、作業系統類型、軟體清單、唯一設備識別碼如（IMEI/androidID/IDFA/OPENUDID/GUID/IDFV、SIM卡IMSI資訊等在內的描述個人常用設備基本情況的資訊</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）等來為您提供商品資訊展示的最優方式。我們也會為了不斷改進和優化上述的功能來使用您的上述個人資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您也可以通過搜索來精准地找到您所需要的商品或服務。我們會保留您的搜索內容以方便您重複輸入來為您展示與您搜索內容相關聯的商品。若您以遊客身份搜索我們的產品</span><span style="font-family:微软雅黑">/服務，我們只會根據熱銷推薦向您提供產品/服務的展示。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請您注意，您的搜索關鍵字資訊無法單獨識別您的身份，不屬於您的個人資訊，我們有權以任何的目的對其進行使用</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">；只有當您的搜索關鍵字資訊與您的其他資訊相互結合使用並可以識別您的身份時，則在結合使用期間，我們會將您的搜索關鍵字資訊作為您的個人資訊，與您的搜索歷史記錄一同按照本隱私政策對其進行處理與保護。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、向您提供產品或服務</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">為便於您使用我們的服務，需要您根據產品或服務的內容，選擇填寫及</span><span style="font-family:微软雅黑">/或提交使用產品或服務所必須的資訊，</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">有時可能還需要您提供身份資訊、手機號碼、電話號碼、位址、銀行或支付交易帳號等個人敏感資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">為向您提供更契合您需求的頁面展示和搜索結果、瞭解產品適配性、識別帳號異常狀態、向您報送錯誤等，我們會收集關於您使用我們產品或服務使用方式的資訊並將這些資訊進行關聯。這些資訊包括</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">位置資訊、帳號資訊、身份資訊、設備資訊、日誌資訊、網路環境</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等，其中設備資訊和日誌資訊是指：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">設備資訊：</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們會根據您在軟體安裝及使用中授予的具體許可權，接收並記錄您所使用的設備相關資訊（例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">設備型號、作業系統版本、設備設置、設備</span><span style="font-family:微软雅黑">MAC位址、唯一設備標識碼（如IMEI/android ID/IDFA/OPENUDID/GUID/IDFV、SIM卡IMSI資訊等在內的描述個人常用設備基本情況的資訊）等軟硬體特徵資訊</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）、設備所在位置相關資訊（例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">IP位址、GPS位置以及能夠提供相關資訊的Wi-Fi接入點、藍牙和基站等感測器資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">日誌資訊：</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">當您使用我們提供的產品或服務時，我們會自動收集您對我們服務的詳細使用情況，作為有關網路日誌保存。例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您的搜索查詢內容、流覽記錄、點擊記錄、</span><span style="font-family:微软雅黑">IP位址、流覽器的類型、電信運營商、使用的語言、訪問日期和時間及您訪問的網頁記錄</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請注意，單獨的設備資訊、日誌資訊等是無法識別特定自然人身份的資訊，為了在您使用設備上為您提供一致的服務，我們會將於您各種設備（電腦、移動設備）上收集到的資訊進行關聯。如果我們將這類非個人資訊與其他資訊結合用於識別特定自然人身份，或者將其與個人資訊結合使用，則在結合使用期間，這類非個人資訊將被視為個人資訊，除取得您授權或法律法規另有規定外，我們會將該類個人資訊做匿名化、去標識化處理。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">匿名化處理</span><span style="font-family:微软雅黑">/去標識化是指對個人資訊進行技術處理，使得個人資訊主體無法被識別。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">為了説明您更好地使用我們的產品或服務，我們會收集相關資訊。例如，我們收集的</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">好友清單、群清單資訊、聲紋特徵值資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。為確保您使用我們服務時能與您認識的人進行聯繫，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如您選擇開啟導入通訊錄功能，我們可能對您連絡人的姓名和電話號碼進行加密，並僅收集加密後的資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在部分服務專案中，為便於向您交付商品或服務，您至少需提供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">收貨人個人身份資訊、姓名、收貨地址、郵遞區號、收貨人、聯繫電話、支付狀態資訊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">。上述所有資訊構成您的</span><span style="font-family:微软雅黑">“訂單資訊”，我們將使用您的訂單資訊來進行您的身份核驗、確定交易、支付結算、完成配送、為您查詢訂單以及提供客服諮詢與售後服務；我們還會使用您的訂單資訊來判斷您的交易是否存在異常以保護您的交易安全。如果您拒絕提供此類資訊，我們將無法完成相關交付服務。如您通過我們服務為其他人訂購商品或服務，您需要提供該實際訂購人的前述資訊。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">向我們提供該實際訂購人的前述資訊之前，您需確保您已經取得其授權同意。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">客戶服務。當您與我們聯繫時，為了及時有效地與您溝通，我們的線上客服和電話客服功能可能會保存您的</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">帳號、電話、住址、郵箱、</span><span style="font-family:微软雅黑">IP位址、通信/通話記錄和內容或您留下的其他聯繫方式</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等資訊，以便與您聯繫或幫助您解決問題，或記錄相關問題的處理方案及結果。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、線下活動、發起退款</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您通過我們的客服或參加我們舉辦的活動、調研時所提交的資訊。例如，您參與我們調研活動時填寫的調查問卷中可能包含您的</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">姓名、電話、手機號碼、位址</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您在我們的平臺訂購商品並通過線下活動領取商品以及</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您向我們發起退款時，您需要向我們提供您的個人資訊以用於確認您的身份，例如您的身份證號、姓名</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">5、定位功能</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">當您開啟設備定位功能並使用我們基於位置提供的相關服務時，收集的有關您位置的資訊，包括：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）您通過具有定位功能的移動設備使用我們的某些與地理位置相關的服務時，通過GPS或WiFi等方式收集的您的地理位置資訊。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）您或其他用戶提供的包含您所處地理位置的即時資訊，例如您提供的帳戶資訊中包含的您所在地區資訊，您或其他人上傳的顯示您當前或曾經所處地理位置的共用資訊，您或其他人共用的照片包含的地理標記資訊。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可以通過移動設備上的關閉定位功能，停止對您的地理位置資訊的收集。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">6、設備許可權調用</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們在提供服務的過程中，可能需要您開啟一些設備存取權限，例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">相機（攝像頭）、相冊（圖片庫）、麥克風、手機通訊錄</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等存取權限，以實現這些功能所涉及的資訊的收集和使用。您也可以在設備的設置功能中隨時選擇關閉部分或全部許可權，從而拒絕我們收集相應的個人資訊。在不同設備中，許可權顯示方式及關閉方式可能有所不同，具體請參考設備及系統開發方說明或指引。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請您注意，您開啟這些許可權即代表您授權我們可以收集和使用這些個人資訊來實現上述的功能，您關閉許可權即代表您取消了這些授權，則我們將不再繼續收集和使用您的這些個人資訊，也無法為您提供上述與這些授權所對應的功能。您關閉許可權的決定不會影響此前基於您的授權所進行的個人資訊的處理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">7、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們通過間接獲得方式收集到的您的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）您使用或訂購協力廠商產品或服務的資訊。為了給您更加便捷的生活服務，本平臺可能會為您接入了豐富的協力廠商服務。您可以通過相關產品模組使用相關功能。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）當您使用本平臺接入的協力廠商服務時，可能需要提交您的個人資訊（部分情況下包括您的身份資訊、聯繫方式、住宿資訊、行程資訊、交易資訊、支付資訊、帳號資訊、昵稱、評論內容、銀行帳號等以及個人明確授權可以共用資訊）。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您授權我們將該資訊提供給為您提供服務的協力廠商，並授權我們間接向協力廠商獲取相關資訊。若您拒絕我們收集此類資訊，我們將無法為您提供相關功能和服務，但不會影響您對產品或服務基礎功能的使用。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）當您使用FB帳號、Apple帳號、google帳號登入我們的產品或服務時，我們需要核實您的身份或綁定您的帳戶資訊，經過您的授權將相關帳號關聯到我們的產品或服務中。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4）如您拒絕提供上述資訊或拒絕授權，可能無法使用我們關聯公司或協力廠商的相應產品或服務，或者無法展示相關資訊，但不影響使用本產品或服務的基礎功能。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">8、為您提供安全保障</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請注意，為確保用戶身份真實性、向您提供更好的安全保障，您需要向我們提供身份證或者護照的證件號碼等個人敏感資訊完成實名認證。如您拒絕提供上述資訊，可能無法使用需要完成實名認證後才能提供的附加服務，但不會影響您使用流覽、搜索等基礎服務。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">為提高您使用我們及我們關聯公司、合作夥伴提供產品或服務的安全性，保護您或其他用戶或公眾的人身財產安全免遭侵害，更好地預防釣魚網站、欺詐、網路漏洞、電腦病毒、網路攻擊、網路侵入等安全風險，更準確地識別違反法律法規或本產品相關協定規則的情況，我們可能使用或整合您的使用者資訊、行為資訊、設備資訊、有關網路日誌以及我們關聯公司、合作夥伴取得您授權或依據法律共用的資訊，來綜合判斷您帳號狀態及交易風險、進行身份驗證、檢測及防範安全事件，並依法採取必要的記錄、審計、分析、處置措施。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">9、其他用途</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）我們可能將不定期地舉辦有關我們產品和服務的調查，或者圍繞運營需要舉辦促銷及抽獎活動，如果您在參與此類調查或活動時需要提供超過本政策許可的個人敏感資訊，我們會事先徵求您的同意。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）我們將資訊用於本政策未載明的其他用途，或者將基於特定目的收集而來的資訊用於其他目的時，會事先徵求您的同意。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）需進行額外說明的是，為確保流暢的產品體驗，您可能會收到來自我們及其合作夥伴外部的協力廠商（下文簡稱“協力廠商”）提供的內容或網路連結。我們對此類協力廠商無控制權。您可選擇是否訪問協力廠商提供的連結、內容、產品和服務。我們無法控制協力廠商的隱私和資料保護政策，此類協力廠商不受到本政策的約束。在向協力廠商提交個人資訊之前，請參見這些協力廠商的隱私保護政策。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">10、征得授權同意的例外</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">根據相關法律法規規定，以下情形中收集您的個人資訊無需征得您的授權同意：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）與國家安全、國防安全有關的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）與公共安全、公共衛生、重大公共利益有關的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）與犯罪偵查、起訴、審判和判決執行等有關的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）出於維護個人資訊主體或其他個人的生命、財產等重大合法權益但又很難得到您本人同意的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">5）所收集的個人資訊是您自行向社會公眾公開的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">6）從合法公開披露的資訊中收集個人資訊的，如合法的新聞報導、政府資訊公開等管道；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">7）根據您的要求簽訂合同所必需的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">8）用於維護所提供的產品或服務的安全穩定運行所必需的，例如發現、處置產品或服務的故障；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">9）為合法的新聞報導所必需的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">10）學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的個人資訊進行去標識化處理的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">11）法律法規規定的其他情形。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如我們停止運營本產品的功能或服務，我們將及時停止繼續收集您個人資訊的活動，將停止運營資訊以通知或公告的形式通知您，對所持有的個人資訊進行刪除或匿名化處理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">二、我們如何使用</span><span style="font-family:微软雅黑">&nbsp;Cookie和同類技術</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;（一）Cookie</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">為確保本產品正常運轉、為您獲得更輕鬆的訪問體驗、向您推薦您可能感興趣的內容，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">我們可能會在您的電腦或移動設備上存儲名為</span><span style="font-family:微软雅黑">Cookie&nbsp;的小資料檔案。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">Cookie通常包含識別字、網站名稱以及一些號碼和字元。借助於Cookie，網站及用戶端能夠存儲您的註冊帳號、郵箱、密碼、偏好、銀行帳號、網路支付和/或交易帳號等資料。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您可根據自己的偏好管理或刪除</span><span style="font-family:微软雅黑">Cookie。您可以清除電腦上保存的所有&nbsp;Cookie，大部分網路流覽器都設有阻止&nbsp;Cookie的功能。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">但如果您這麼做，則需要在每一次訪問我們的網站時更改用戶設置。如需詳細瞭解如何更改流覽器設置，請訪問您使用的流覽器的相關設置頁面。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）網站信標和圖元標籤</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">除</span><span style="font-family:微软雅黑">&nbsp;Cookie外，我們可能還會在網站或用戶端上使用網站信標和圖元標籤等其他同類技術。例如，我們向您發送的電子郵件可能含有連結至我們網站內容的位址連結，如果您點擊該連結，我們則會跟蹤此次點擊，説明我們瞭解您的產品或服務偏好以便於我們主動改善客戶服務體驗。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">網站信標通常是一種嵌入到網站或電子郵件中的透明圖像。借助於電子郵件中的圖元標籤，我們能夠獲知電子郵件是否被打開。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如果您不希望自己的活動以這種方式被追蹤，則可以隨時從我們的寄信名單中退訂或按照本《隱私政策》所載明的聯繫方式聯繫我們進行處理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（三）</span><span style="font-family:微软雅黑">Do NotTrack（請勿追蹤）</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">很多網路流覽器均設有</span><span style="font-family:微软雅黑">&nbsp;Do NotTrack功能，該功能可向網站發佈&nbsp;Do NotTrack 請求。目前，主要互聯網標準組織尚未設立相關政策來規定網站應如何應對此類請求。但如果您的流覽器啟用了Do NotTrack，那麼我們的所有網站都會尊重您的選擇。但請您注意，可能因此影響您使用本平臺提供產品及服務的體驗。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">三、我們如何共用、轉讓、公開披露您的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）共用</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您理解並同意，我們將確保資料接收方無法重新識別個人資訊主體的前提下，通過共用的方式提供給協力廠商。我們可能在以下情況下與本平臺以外的任何公司、組織和個人分享您的個人資訊：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、在獲取明確同意的情況下共用：獲得您的明確同意後，我們會與其他方共用您的個人資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、我們可能會根據法律法規規定，或按行政主管部門的強制性要求，對外共用您的個人資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、與我們的關聯公司共用：您的個人資訊可能會與本平臺的關聯公司共用。我們只會共用必要的個人資訊，且受本《隱私政策》中所聲明目的的約束。關聯公司如要改變個人資訊的處理目的，將再次徵求您的授權同意。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、與授權合作夥伴共用：僅為實現本政策中聲明的目的，我們的某些服務將由授權合作夥伴提供。我們可能會與合作夥伴共用您的某些個人資訊，以提供更好的客戶服務和使用者體驗。我們僅會出於合法、正當、必要、特定、明確的目的共用您的個人資訊，並且只會共用提供服務所必要的個人資訊。我們的合作夥伴無權將共用的個人資訊用於任何其他用途。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">目前，我們的授權合作夥伴包括以下兩大類型：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）廣告、分析服務類的授權合作夥伴</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。除非得到您的許可，否則我們不會將您的個人身份資訊（指足以識別您身份的資訊，例如姓名或電子郵箱，通過這些資訊可以聯繫到您或識別您的身份）與提供廣告、分析服務的合作夥伴分享。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2）</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">供應商、服務提供者和其他合作夥伴</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。我們將資訊發送給支援我們業務的供應商、服務提供者和其他合作夥伴，例如代表我們推送通知的通訊服務提供者、提供認證服務的通訊服務提供者。這些支援包括提供技術基礎設施服務、分析我們服務的使用方式、衡量廣告和服務的有效性、提供客戶服務、支付便利及其他為本平臺產品及服務提供的支援。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）轉讓</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們不會將使用者的個人資訊轉讓給任何公司、組織和個人，但按照中華人民共和國的法律法規要求必須轉讓的除外，且我們會按照法律法規所要求的標準繼續保護或要求新的管理者按照本政策繼續保護使用者的個人資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）公開披露</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們僅會在以下情況下，公開披露您的個人資訊：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、獲得您明確同意後；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、與國家安全、國防安全有關的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、與公共安全、公共衛生、重大公共利益有關的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、與犯罪偵查、起訴、審判和判決執行等有關的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">5、出於維護資訊主體或其他個人的生命、財產等重大合法權益但又很難得到您本人同意的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">6、所收集的資訊是您自行向社會公眾公開的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">7、從合法公開披露的資訊中收集資訊的，如合法的新聞報導、政府資訊公開等管道；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">8、根據您的要求簽訂合同所必需的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">9、用於維護本平臺服務的安全穩定運行所必需的，例如發現、處置產品或服務的故障；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">10、為合法的新聞報導所必需的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">11、學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的資訊進行去標識化處理的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">12、法律法規規定的其他情形。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">四、我們如何儲存和保護資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們僅在本《隱私政策》所述目的所必需期間和法律法規要求的時限內保留您的資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們在中華人民共和國境內運營中收集和產生的資訊，存儲在中國境內。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們將採取以下手段保護您的資訊：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）資料安全技術措施</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們將在合理的安全水準內使用各種安全保護措施以保障資訊的安全。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">例如，我們使用加密技術（例如，</span><span style="font-family:微软雅黑">TLS、SSL）、匿名化處理等手段來保護您的個人資訊。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）我們為保護資訊採取的其他安全措施</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們通過建立資料分類分級制度、資料安全管理規範、資料安全開發規範來管理規範資訊的存儲和使用。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們通過資訊接觸者保密協定、監控和審計機制來對資料進行全面安全控制。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">加強安全意識。我們還會舉辦安全和隱私保護培訓課程，加強員工對於保護資訊重要性的認識。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）我們僅允許有必要知曉這些資訊的本平臺員工、合作夥伴訪問您的資訊，並為此設置了嚴格的存取權限控制和監控機制。我們同時要求可能接觸到您的資訊的所有人員履行相應的保密義務。如果未能履行這些義務，可能會被追究法律責任或被中止與本平臺的合作關係。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（四）我們會採取一切合理可行的措施，確保未收集無關的資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（五）</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">互聯網並非絕對安全的環境，而且電子郵件、即時通訊、社交軟體或其他服務軟體等與其他使用者的交流方式無法確定是否完全加密，我們建議您使用此類工具時請使用複雜密碼，並注意保護您的資訊安全。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（六）互聯網環境並非百分之百安全，我們將盡力確保或擔保您發送給我們的任何資訊的安全性。如果我們的物理、技術、或管理防護設施遭到破壞，導致資訊被非授權訪問、公開披露、篡改、或毀壞，導致您的合法權益受損，我們將承擔相應的法律責任。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（七）安全事件處置</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在通過本平臺服務與協力廠商進行溝通或購買商品及服務時，您不可避免的要向交易對方或潛在的交易對方披露自己的資訊，如聯絡方式或者郵政位址等。請您妥善保護自己的資訊，僅在必要的情形下向他人提供。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">為應對資訊洩露、損毀和丟失等可能出現的風險，我們制定了多項制度，明確安全事件、安全性漏洞的分類分級標準及相應的處理流程。我們也為安全事件建立了專門的應急回應團隊，按照安全事件處置規範要求，針對不同安全事件啟動安全預案，進行止損、分析、定位、制定補救措施、聯合相關部門進行溯源和打擊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在不幸發生資訊安全事件後，我們將按照法律法規的要求，及時向您告知：安全事件的基本情況和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。我們同時將及時將事件相關情況以郵件、信函、電話、推送通知等方式告知您，難以逐一告知資訊主體時，我們會採取合理、有效的方式發佈公告。同時，我們還將按照監管部門要求，主動上報資訊安全事件的處置情況。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">請您理解，由於技術的限制以及風險防範的局限，即便我們已經儘量加強安全措施，也無法始終保證資訊百分之百的安全。您需要瞭解，您接入本平臺服務所用的系統和通訊網路，有可能因我們可控範圍外的情況而發生問題。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">五、我們可能向您發送的資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）信息推送</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您在使用我們的服務時，我們可能向您發送</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">電子郵件、短信、彩信、資訊或推送通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可以按照我們的相關提示，在設備上選擇取消訂閱。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）與服務有關的公告</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們可能在必要時（例如，因系統維護而暫停某一項服務時）向您發出與服務有關的公告。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可能無法取消這些與服務有關、性質不屬於廣告的公告。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">六、您如何訪問和管理自己的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）訪問您的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、您有權訪問您的個人資訊，法律法規規定的例外情況除外。如果您希望訪問或編輯您的帳戶中的個人基本資料資訊，您可以通過登錄您的帳號執行此類操作。您可隨時查詢並管理您帳戶下的個人資訊，帳號名稱是您重要的網路身份標識，出於安全性和身份識別的考慮，在註冊之後無法變更。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、如果您無法通過上述路徑訪問該等個人資訊，您可以隨時通過我們客服與我們取得聯繫。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們將儘快回復您的訪問請求。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、對於您在使用我們的產品或服務過程中產生的其他個人資訊，我們將根據本條“第（七）款回應您的上述請求”中的相關安排向您提供。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）更正或補充您的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">當您發現我們處理的關於您的個人資訊有錯誤時，您有權要求我們做出更正或補充。您可以通過</span><span style="font-family:微软雅黑">“（一）訪問您的個人資訊”中列明的方式提出更正或補充申請。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）刪除您的個人資訊</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、您可以通過“（一）訪問您的個人資訊”中列明的方式刪除您的部分個人資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、在以下情形中，您可以向我們提出刪除個人資訊的請求：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）如果我們處理個人資訊的行為違反法律法規；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）如果我們收集、使用您的個人資訊，卻未征得您的明確同意；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）如果我們處理個人資訊的行為嚴重違反了與您的約定；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）如果您不再使用我們的產品或服務，或您主動登出了帳號；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">5）如果我們永久不再為您提供產品或服務。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、若我們決定響應您的刪除請求，我們還將同時盡可能通知從我們處獲得您的個人資訊的主體，要求其及時刪除，除非法律法規另有規定，或這些主體獲得您的獨立授權。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、當您從我們的服務中刪除資訊後，我們可能不會立即從備份系統中刪除相應的資訊，但會在備份更新時刪除這些資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（四）改變您授權同意的範圍</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">每個業務功能需要一些基本的個人資訊才能得以完成（見本《隱私權政策》第一條）。除此之外，您可以通過解除綁定、修改個人設置、刪除相關資訊等方式撤回部分授權，也可以通過關閉功能的方式撤銷部分授權。例如，若您在使用地理位置相關服務時，希望停止分享您的地理位置資訊，您可通過手機定位關閉功能、軟硬體服務商及通訊服務提供者的關閉方式停止分享。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">當您收回同意後，我們將不再處理相應的個人資訊。但您收回同意的決定，不會影響此前基於您的授權而開展的個人資訊處理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（五）個人資訊主體登出帳戶</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、您可以自行在“登出帳戶”頁面提交帳戶登出申請。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、在您主動註銷帳戶之後，我們將停止為您提供產品或服務，根據適用法律的要求刪除您的個人資訊，或使其匿名化處理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（六）約束資訊系統自動決策</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在某些業務功能中，我們可能僅依據資訊系統、演算法等在內的非人工自動決策機制做出決定。如果這些決定顯著影響您的合法權益，您有權要求我們做出解釋，我們也將在不侵害本產品商業秘密或其他使用者權益、社會公共利益的前提下提供申訴方法。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（七）回應您的上述請求</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、為保障安全，您可能需要提供書面請求，或以其他方式證明您的身份。我們可能會先要求您驗證自己的身份，然後再處理您的請求。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、我們將儘快做出答覆。如您不滿意，還可以通過本隱私政策第九條載明的客服聯繫方式發起投訴。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">對於您合理的請求，我們原則上不收取費用，但對多次重複、超出合理限度的請求，我們將視情收取一定成本費用。對於那些無端重複、需要過多技術手段（例如，需要開發新系統或從根本上改變現行慣例）、給他人合法權益帶來風險或者非常不切實際的請求，我們可能會予以拒絕。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、在以下情形中，按照法律法規要求，我們將無法回應您的請求：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）與國家安全、國防安全有關的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）與公共安全、公共衛生、重大公共利益有關的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）與犯罪偵查、起訴、審判和執行判決等有關的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）有充分證據表明個人資訊主體存在主觀惡意或濫用權利的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">5）響應您的請求將導致您或其他個人、組織的合法權益受到嚴重損害的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">6）涉及商業秘密的。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">七、廣告服務</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本平臺服務可能使用您的相關資訊，向您提供與您更加相關的廣告，如可能連結至協力廠商提供的社交媒體或其他服務（包括網站或其他服務形式）。包括：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（一）您可利用</span><span style="font-family:微软雅黑">“分享”鍵將某些內容分享到本平臺服務，或您可利用協力廠商服務登錄本平臺服務。這些功能可能會收集您的資訊（包括您的日誌資訊），並可能在您的電腦裝置COOKIES，從而正常運行上述功能；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）我們通過廣告或我們服務的其他方式向您提供連結，使您可以接入協力廠商的服務或網站；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）其他接入協力廠商服務的情形。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">該等協力廠商社交媒體或其他服務由相關的協力廠商負責運營。您使用該等協力廠商的社交媒體服務或其他服務（包括您向該等協力廠商提供的任何資訊），須受協力廠商自己的服務條款及資訊保護聲明（而非本《隱私政策》）約束，您需要仔細閱讀其條款。本《隱私政策》僅適用於我們所收集的任何資訊，並不適用於任何協力廠商提供的服務或協力廠商的資訊使用規則，而我們對任何協力廠商使用由您提供的資訊不承擔任何責任。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如您不希望我們將您的個人資訊用作前述廣告用途，您可以通過我們在廣告中提供的相關提示，或在特定服務中提供的指引，要求我們停止為上述用途使用您的個人資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">八、未成年人限制</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">任何未成年人參加網上活動應事先取得家長或其法定監護人（以下簡稱</span><span style="font-family:微软雅黑">&quot;監護人&quot;）的同意。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們將根據國家相關法律法規的規定保護未成年人的相關資訊。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們鼓勵父母或監護人指導未成年人使用本平臺服務。我們建議未成年人鼓勵他們的父母或監護人閱讀本《隱私政策》，並建議未成年人在提交資訊之前尋求父母或監護人的同意和指導。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">九、通知及變更</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們可能適時修改本《隱私政策》的條款，該等修改構成本《隱私政策》的一部分。對於重大變更，我們會提供更顯著的通知，您可以選擇停止使用本平臺服務；在該種情況下，如您仍然繼續使用本平臺服務的，即表示同意受經修訂的本《隱私政策》的約束。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">任何修改都會將您的滿意度置於首位。我們鼓勵您在每次使用本平臺服務時都查閱我們的隱私政策。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我們可能在必需時（例如當我們由於系統維護而暫停某一項服務時）發出與服務有關的公告。您可能無法取消這些與服務有關、性質不屬於推廣的公告。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">最後，您必須對您的帳號和密碼資訊負有保密義務。任何情況下，請小心妥善保管。</span>
</p>
<p>
    <br/>
</p>
`