/*
 * @Author: 蛋蛋
 * @Date: 2021-04-06 17:53:20
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-15 17:15:34
 */
import React,{useState,useEffect} from 'react'
import {createPortal} from 'react-dom';
import styles from './Kefu.module.scss';
import {getConcatData} from '../../api/index';
import intl from 'react-intl-universal';
function Kefu(props:any) {
  const [node,setNode] = useState<any>('')
  const [concatData,setConcatData] = useState({attendant_desc:'',attendant_time:'',attendant_mail:''})
  useEffect(() => {
    let el:any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el);
    getConcatData().then((res:any)=>{
      if(res.code===0){
        console.log(res.data)
        setConcatData(res.data)
      }
    })
    return ()=>{
      document.body.removeChild(el);
    }
  }, [])

  return node && createPortal(
    <>
      <div className={styles['concat-bg']}>
        <div className={styles.box}>
          <div className={styles['concat-title']}>{concatData.attendant_desc}</div>
          <div className={styles['concat-desc']}>{intl.get('1012')}: {concatData.attendant_time}</div>
          <div className={styles['concat-desc']}>Email : {concatData.attendant_mail}</div>
        <div className={styles['btn']} onClick={()=>{props.onClose()}}>{intl.get('1013')}</div>
        </div>
      </div>
      <div className={styles.mark}></div>
    </>,
    node
  )
}

export default Kefu


