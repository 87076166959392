/*
 * @Author: 脉迈
 * @Date: 2021-11-10 11:50:43
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:50:57
 */
import axios from 'axios'
import { getLocal } from './index'

let baseURL:string = '/api'
if( process.env.NODE_ENV === 'production' ) {
  baseURL = '/api'
} else {
  baseURL = '/api'
}
// 拦截器
axios.interceptors.response.use((response) => {
  return response
}, (error) => { 
  return Promise.reject(error)
})
axios.interceptors.request.use((config) => {
  // console.log(config)
  // config.headers['Accept'] = 'application/vnd.dpexpo.v1+json'
  config.baseURL = baseURL
  config.timeout = 10000
  return config;
}, (error) => {
  return Promise.reject(error)
})

// axios的get请求
export function getAxios({
  url,
  params={}
}:{url:string,params?:any}) {
  return new Promise((resolve, reject) => {
      axios.get(url, {
        params:{...params,language:getLocal('lang')},
      }).then(res => {
          resolve(res.data)
      }).catch(err => {
          reject(err)
      })
  })
}

// axios的post请求
export function postAxios({
  url,
  data
}:{url:string,data?:any}) {
  return new Promise((resolve, reject) => {
      axios({
          url,
          method: 'post',
          data:{...data,language:getLocal('lang')}
      }).then(res => {
          resolve(res.data)
      }).catch(err => {
          reject(err)
      })
  })
}

export default axios