/*
 * @Author: 蛋蛋
 * @Date: 2021-04-06 16:21:54
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-10 15:05:08
 */

import React,{useState,useEffect} from 'react';
import {createPortal} from 'react-dom';
import styles from './GameDetail.module.scss';
import {getGameDetail} from '../../api/index';
const Index = function GameDetail(props:any) {
  const [node,setNode] = useState<any>('')
  const [data, setData] = useState({
    head_picture:'',
    game_name:'',
    game_content:'',
    url_list:{
      facebook:{
        url:''
      },
      google:{
        url:''
      },
      apple:{
        url:''
      }
    }
  })
  useEffect(() => {
    let el:any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el);
    getGameDetail({game_id:props.id}).then((res:any)=>{
      if(res.code===0){
        setData(res.data)
      }
    })
    return ()=>{
      document.body.removeChild(el);
    }
  }, [props.id])

  return node && createPortal(
    <>
      <div className={styles.content}>
        <i className={styles.close} onClick={()=>{props.onClose()}}></i>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img src={data.head_picture} alt=""/>
          </div>
          <div className={styles.con}>
            <h3>{data.game_name}</h3>
            <div className={styles.article} dangerouslySetInnerHTML={{__html: data.game_content}}>
              
            </div>
            <div className={styles.btns}>
              {data.url_list.facebook ? <a href={data.url_list.facebook.url} className={`${styles.item} ${styles['fb-icon']}`}> </a> : <></>}
              {data.url_list.google ? <a href={data.url_list.google.url} className={`${styles.item} ${styles['google-icon']}`}> </a> : <></>}
              {data.url_list.apple ? <a href={data.url_list.apple.url} className={`${styles.item} ${styles['apple-icon']}`} > </a> : <></>}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mark}></div>
    </>,
    node
  )
}


export default Index;
