/*
 * @Author: 脉迈
 * @Date: 2021-11-10 14:41:14
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-02-14 18:02:59
 */
import React from 'react';
import styles from './Footer.module.scss';
import intl from 'react-intl-universal';
import {NavLink, useLocation} from 'react-router-dom';
const Footer =()=> {
  const location = useLocation();
  return (
    <div>
       <div className={styles['footer']}>
         <div className={styles['footer-container']}>
           <span>NEWAGAMESCO.,LTD.</span>
           |
           <span><NavLink to={location.pathname === '/index' ||  location.pathname === '/yhxy1' ||  location.pathname === '/us'  ? '/yhxy1' : '/yhxy'}>{intl.get('1008')}</NavLink></span>
           |
           <span><NavLink to={location.pathname === '/index' ||  location.pathname === '/yhxy1' ||  location.pathname === '/us'  ? '/yszc1' : '/yszc'}>{intl.get('1009')}</NavLink></span>
         </div>
       </div>
    </div>
  )
}

export default Footer;
